import { Options } from '@ministrary/widget/components/dynamic-form/dynamic-form-config.model';

export enum eGroupMemberType {
  VISITOR = 'visitor',
  MEMBER = 'member',
  LEADER = 'leader'
}

export const GROUP_MEMBER_TYPE_OPTIONS: Options[] = [
  { label: 'Visitante', value: eGroupMemberType.VISITOR },
  { label: 'Membro', value: eGroupMemberType.MEMBER },
  { label: 'Líder', value: eGroupMemberType.LEADER }
];
